import { AfterViewInit, ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { AngieAppRoutes } from 'src/app/angie-app.routes';
import {
	AvailableLanguage,
	LanguagePickModalComponent
} from 'src/app/angie-shared/components/modals/language-pick-modal';
import { UserService } from 'src/app/angie-shared/services/user.service';
import { avatarInitials, firstTwoLetters } from 'src/app/angie-shared/utils';
import {
	BaseResponse,
	CoreRoutes,
	DataService,
	DsConfig,
	LuUser,
	LuUserRoleCode,
	StateService,
	WindowRefService
} from 'src/app/core';
import { PortalMeta } from 'src/app/core/core.models';
import { LanguageService } from 'src/app/core/services/language.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { InsightsService } from 'src/app/modules/insights/services/insights.service';
import { BaseComponent } from '../../../../angie-shared/components/base/base.component';
import { SupportAccessModalComponent } from '../../../../angie-shared/components/modals/support-access-modal/support-access-modal.component';
import { LU_STICKY_FILTERS_STORAGE_KEY } from '../../../../angie-shared/modules/sticky-filters/sticky-filters.model';
import { RoleCodeToLabelPipe } from '../../../../angie-shared/pipes/role-code-to-label/role-code-to-label.pipe';
import { SupportAccessRoutes } from '../../../../angie-shared/routes';

/**
 * User Menu dropdown
 *
 * User menu in layout at top right
 *
 * List out users options (Language selection / User details etc)
 */
@Component({
	selector: 'angie-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMenuComponent extends BaseComponent implements AfterViewInit {
	/**
	 * User Menu Input - data for logged in user to get his information
	 */
	@Input() userMenu: LuUser;
	luUser: LuUser = this.stateService.luUser;
	/**
	 * Avatar Initials - generate initials based on first and last name
	 */
	initials = avatarInitials;
	/**
	 * Util to get first two letters based on passed string
	 */
	firstTwoLetters = firstTwoLetters;

	/**
	 * Portal meta - conditionals to format user menu
	 */

	portalMeta: PortalMeta = this.stateService.portalMeta;

	isLearner = this.userService.isLearner();

	isInLearnerView = this.stateService.isInLearnerView();

	LuUserRoleCode = LuUserRoleCode;

	switchViewLabel: string;
	/**
	 * Navigation in user menu
	 */
	userMenuNavigation = [
		{
			label: 'pagetitles.billing',
			url: '/billings',
			icon: 'lu-bill',
			hasDivider: true,
			target: '_self',
			show: this.luUser.role_code === LuUserRoleCode.ADMIN && this.portalMeta.show_billing_tab && !this.isInLearnerView
		},
		{
			label: 'pagetitles.support',
			icon: 'lu-support',
			url: '/support',
			hasDivider: this.luUser.role_code === LuUserRoleCode.ADMIN && !this.portalMeta.show_billing_tab,
			target: '_self',
			show:
				this.luUser.role_code === LuUserRoleCode.ADMIN && this.portalMeta.show_desk_support && !this.isInLearnerView,
			onClick: () => {
				this.accessSupportDesk();
			}
		},
		{
			label: 'pagetitles.knowledge_base',
			icon: 'lu-knowledge-base',
			url: 'https://support.learnupon.com/',
			target: '_blank',
			show:
				this.luUser.role_code === LuUserRoleCode.ADMIN &&
				this.portalMeta.show_desk_support &&
				!this.stateService.isInLearnerView()
		}
	];

	learnerNavigation = [
		{
			icon: 'lu-dashboard',
			label: 'lup.tabs.dashboard',
			url: `/${AngieAppRoutes.DASHBOARD}`,
			external: true,
			hasDivider: true,
			target: '_self',
			show:
				!(
					(this.portalMeta.learner_dashboard_enabled && this.userService.isLearner()) ||
					this.stateService.isInLearnerView()
				) &&
				(this.luUser.role_code === LuUserRoleCode.ADMIN ||
					this.luUser.role_code === LuUserRoleCode.MANAGER ||
					this.luUser.role_code === LuUserRoleCode.INSTRUCTOR ||
					this.userService.isLearner())
		},
		{
			icon: 'lu-home',
			label: 'lup.learner_dashboard_nav_title_home',
			url: `/${AngieAppRoutes.LEARNER_DASHBOARD}`,
			external: true,
			hasDivider: true,
			target: '_self',
			show:
				((this.portalMeta.learner_dashboard_enabled && this.userService.isLearner()) ||
					this.stateService.isInLearnerView()) &&
				(this.luUser.role_code === LuUserRoleCode.ADMIN ||
					this.luUser.role_code === LuUserRoleCode.MANAGER ||
					this.luUser.role_code === LuUserRoleCode.INSTRUCTOR ||
					this.userService.isLearner())
		},
		{
			icon: 'lu-courses',
			label: 'lup.my_learning_title',
			url: `/${AngieAppRoutes.MY_LEARNING}`,
			external: true,
			show:
				this.portalMeta.learner_dashboard_enabled &&
				this.portalMeta.my_learning_enabled &&
				(this.userService.isLearner() || this.stateService.isInLearnerView())
		},
		{
			icon: 'lu-courses',
			label: 'lup.subnav.lup_dashboard',
			url: 'dashboard',
			external: true,
			show:
				this.portalMeta.learner_dashboard_enabled &&
				!this.portalMeta.my_learning_enabled &&
				this.userService.isLearner()
		},
		{
			icon: 'lu-courses',
			label: 'lup.subnav.lup_dashboard',
			url: 'dashboard?display=my_courses',
			external: true,
			show:
				this.portalMeta.learner_dashboard_enabled &&
				!this.portalMeta.my_learning_enabled &&
				!this.userService.isLearner()
		},
		{
			icon: 'lu-catalog',
			label: 'lup.tabs.catalog',
			target: '_self',
			url: `/${AngieAppRoutes.CATALOG}`,
			external: true,
			show:
				(this.luUser.role_code === LuUserRoleCode.MEMBER || this.stateService.isInLearnerView()) &&
				this.portalMeta.catalog_enabled
		},
		{
			icon: 'lu-resources',
			label: 'lup.tabs.resources',
			target: '_self',
			url: `/${AngieAppRoutes.LEARNER_RESOURCE_LIST}`,
			external: true,
			show:
				(this.luUser.role_code === LuUserRoleCode.MEMBER || this.stateService.isInLearnerView()) &&
				this.portalMeta.learner_resources_count
		},
		{
			icon: 'lu-store',
			label: 'lup.tabs.store',
			target: '_self',
			url: `/${AngieAppRoutes.STORE}`,
			external: true,
			show:
				(this.luUser.role_code === LuUserRoleCode.MEMBER || this.stateService.isInLearnerView()) &&
				this.portalMeta.ecomms_enabled
		},
		{
			icon: 'lu-forums',
			label: 'lup.tabs.forums',
			target: '_self',
			url: `/${AngieAppRoutes.FORUMS}`,
			external: true,
			show:
				(this.luUser.role_code === LuUserRoleCode.MEMBER || this.stateService.isInLearnerView()) &&
				this.portalMeta.forums_enabled
		}
	];

	private readonly insightsService = inject(InsightsService);

	/**
	 * Constructor
	 */
	constructor(
		public router: Router,
		public userService: UserService,
		private readonly modalService: BsModalService,
		private readonly dataService: DataService,
		private readonly stateService: StateService,
		public readonly languageService: LanguageService,
		private readonly windowRefService: WindowRefService,
		private readonly localStorageService: LocalStorageService,
		private translateService: TranslateService
	) {
		super();
	}

	ngAfterViewInit(): void {
		this.switchViewLabel = this.translateService.instant(
			'lup.learner_dashboard_switch_to_' +
				(this.isInLearnerView ? new RoleCodeToLabelPipe().transform(this.userMenu.role_code) : 'learner') +
				'_role'
		);
	}

	/**
	 * On language pick click
	 *
	 * it calls this function to show modal with all available languages listed out
	 */

	onPickLanguagesModal(): void {
		this.loadLanguages().subscribe(response => {
			this.modalService.show(LanguagePickModalComponent, {
				initialState: {
					availableLanguages: response.data
				}
			});
		});
	}

	signOutClicked(): void {
		this.insightsService.sendEvents();
		this.clearLocalStorage();
	}

	clearLocalStorage(): void {
		this.localStorageService.removeData('authToken');
		this.localStorageService.removeData('navOpen');
		this.localStorageService.removeData(LU_STICKY_FILTERS_STORAGE_KEY);
		this.localStorageService.removeData('isLuCoursePlayerNavOpen');
	}

	/**
	 * Once we try to access support desk - it checked with BE if we can provide access or user should verify his email address
	 */
	accessSupportDesk(): void {
		const { email } = this.stateService.luUser;
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(SupportAccessRoutes.CHECK_SUPPORT_ACCESS);
		this.dataService
			.get(dsConfig)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: BaseResponse<boolean>) => {
					const { data } = response;
					if (data) {
						this.windowRefService.getWindow().open('/support', '_blank');
						return;
					}
					this.modalService.show(SupportAccessModalComponent, {
						initialState: {
							email
						}
					});
				}
			});
	}

	get activeUrl(): string {
		return this.router.url;
	}

	private loadLanguages(): Observable<BaseResponse<AvailableLanguage[]>> {
		const dsConfig: DsConfig = this.dataService.getDefaultConfig(CoreRoutes.LANGUAGES);
		dsConfig.primarySpinnerConfig.show = false;
		return this.dataService.get<AvailableLanguage[]>(dsConfig).pipe(takeUntil(this.destroy$));
	}

	switchViewMode(): void {
		this.windowRefService.getWindow().location.href = this.isInLearnerView
			? AngieAppRoutes.DASHBOARD
			: AngieAppRoutes.LEARNER_DASHBOARD;
	}
}
